import React from 'react';
import './Sidebar.scss'
import { Link } from 'react-router-dom';
import { SiBookstack } from "react-icons/si";
import { MdLibraryBooks } from "react-icons/md";
import { MdQuiz } from "react-icons/md";
import { MdOndemandVideo } from "react-icons/md";
import { FaRegNewspaper } from "react-icons/fa";
import { MdAutoStories } from "react-icons/md";
import { FaWindowClose } from "react-icons/fa";
import { LuPanelTopOpen } from "react-icons/lu";


const Sidebar = ({ title, functionClick, showLeftSidebar, showRightSidebar, showLeftSidebar2 }) => {

    if(title === "Left Sidebar"){
        return (
            <div  className='sidebar_Container'>
            <section className='sidebar_Container_Header'>
              <h3>Practice Skills / ਅਭਿਆਸ</h3>
              <button onClick={() => functionClick(title)}>{showLeftSidebar? <><FaWindowClose/></> : <><LuPanelTopOpen/></> }</button>
            </section>
            {showLeftSidebar ? <section>
              <ul>
                  <Link to='/books'><li><SiBookstack/>  Books / ਕਿਤਾਬਾਂ</li></Link>
                  <Link to='/workshop'><li><MdLibraryBooks/>  Worksheets / ਵਰਕਸ਼ੀਟਾਂ</li></Link>
                  <Link to='/quiz'><li><MdQuiz/>  Play Quiz / ਕਵਿਜ਼ ਖੇਡੋ</li></Link>
                  <Link to='/videos' className='mobile-tablet-only'><li><MdOndemandVideo/>  Videos / ਵੀਡੀਓਜ਼</li></Link>
                  <Link to='/stories' className='mobile-tablet-only'><li><MdAutoStories/>  Stories / ਕਹਾਣੀਆਂ</li></Link>
                  <Link to='/activities' className='mobile-tablet-only'><li><FaRegNewspaper/>  Activties / ਗਤੀਵਿਧੀਆਂ</li></Link>
              </ul>
            </section> 
            : <></> }
          </div>
        )
    } 
    if(title === "Left Sidebar2"){
        return (
            <div  className='sidebar_Container'>
            <section className='sidebar_Container_Header'>
              <h3>Worksheets 2</h3>
              <button onClick={() => functionClick(title)}>{showLeftSidebar2 ? <>Close/ਬੰਦ ਕਰੋ</> : <>Open/ਖੋਲ੍ਹੋ</> }</button>
            </section>
            {showLeftSidebar2 ? <section>
              <ul>
                  <Link to='/'><li>Alphabets</li></Link>
                  <Link to='/workshop'><li>Worksheets</li></Link>
                  <Link to='/quiz'><li>Play Quiz</li></Link>
              </ul>
            </section> 
            : <></> }
          </div>
        )
    }
    if(title === "Right Sidebar"){

        return (
            <div  className='sidebar_Container right_Sidebar'>
            <section className='sidebar_Container_Header'>
                <button onClick={() => functionClick(title)}>{showRightSidebar? <><FaWindowClose/></> : <><LuPanelTopOpen/></> }</button>
                <h3>Entertainment / ਮਨੋਰੰਜਨ</h3>
            </section>
            {showRightSidebar ? <section>
              <ul>
                  <Link to='/videos'><li><MdOndemandVideo/>  Videos / ਵੀਡੀਓਜ਼</li></Link>
                  <Link to='/stories'><li><MdAutoStories/>  Stories / ਕਹਾਣੀਆਂ</li></Link>
                  <Link to='/activities'><li><FaRegNewspaper/>  Activties / ਗਤੀਵਿਧੀਆਂ</li></Link>
              </ul>
            </section> 
            : <></> }
            </div>
        );
    }
};

export default Sidebar;
