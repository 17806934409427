import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import './QuizHome.scss'

const QuizHome = ({Helmet}) => {


  useEffect(()=>{
    window.scroll(0, 0)
},[])

  return (
    <div className='quizhome' >
      <Helmet>
            <title>Play Quiz</title>
      </Helmet>
      <h2>Select Type of Quiz / ਕਵਿਜ਼ ਦੀ ਕਿਸਮ ਚੁਣੋ</h2 >
      <Link to="/quiz/1"><p>Image Quiz / ਚਿੱਤਰ ਕਵਿਜ਼</p></Link>
      <Link to="/quiz/2"><p>Text Quiz / ਟੈਕਸਟ ਕਵਿਜ਼</p></Link>
    </div>
  )
}

export default QuizHome
