// import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
// import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.scss'
// import data from './Header.json'
import Logo from '../../Assets/Images/logo.png'
// import Logo2 from '../../Assets/Images/logo2.png'
import Logo2 from '../../Assets/Images/logo.jpg' 
import { SiBookstack } from "react-icons/si";
import { MdLibraryBooks } from "react-icons/md";
import { MdQuiz } from "react-icons/md";
import { MdOndemandVideo } from "react-icons/md";
import { FaRegNewspaper } from "react-icons/fa";
import { MdAutoStories } from "react-icons/md";



function Header() {
  return (
    <>
      {[false].map((expand) => (
        <Navbar key={expand} expand={expand} className="headertop"  sticky='stick'>
          <Container fluid>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <h4>Punjabi Learning Hub</h4>
            <Navbar.Brand href="/"><img src={Logo} alt='logo' className='header_Logo'></img></Navbar.Brand>

            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Punjabi Learning Hub
                </Offcanvas.Title>
              </Offcanvas.Header>
              <img src={Logo2} alt='logo' className='sideLogo'/>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="/">Home / ਘਰ</Nav.Link>
                  <NavDropdown
                    title="Practice/Learn - ਅਭਿਆਸ / ਸਿੱਖੋ"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="/workshop"><MdLibraryBooks/> Worksheets / ਵਰਕਸ਼ੀਟਾਂ</NavDropdown.Item>
                    <hr/>
                    <NavDropdown.Item href="/books">
                      <SiBookstack/>  Books / ਕਿਤਾਬਾਂ
                    </NavDropdown.Item>
                    <hr/>
                    <NavDropdown.Item href="/quiz">
                      <MdQuiz/>  Play Quiz / ਕਵਿਜ਼ ਖੇਡੋ
                    </NavDropdown.Item>
                  
                  </NavDropdown>
                  <NavDropdown
                    title="Entertainment / ਮਨੋਰੰਜਨ"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="/videos"><MdOndemandVideo/>  Videos / ਵੀਡੀਓ</NavDropdown.Item>
                    <hr/>
                    <NavDropdown.Item href="/stories">
                      <MdAutoStories/>  Stories / ਕਹਾਣੀਆਂ
                    </NavDropdown.Item>
                    <hr/>
                    <NavDropdown.Item href="/activities">
                      <FaRegNewspaper/>  Activties / ਗਤੀਵਿਧੀਆਂ
                    </NavDropdown.Item>
                  
                  </NavDropdown>
                  <Nav.Link href="/contactus">Contact Us / ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default Header;
