import React from 'react'
import Logo from '../../Assets/Images/banner.png'
import DLogo from '../../Assets/Images/logostrip2.png'
import './LogoStrip.scss'

const LogoStrip = () => {
  return (
    <div className='logoStrip' style={{backgroundColor : "lightyellow"}}>
      <img src={Logo} alt='logostrip' style={{ width : "100%"}} className='mobile-tablet'/>
      <img src={DLogo} alt='logostrip' style={{ width : "100%"}} className='desktop'/>
    </div>
    
  )
}

export default LogoStrip
