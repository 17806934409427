import React, { Suspense, lazy } from 'react';
import './App.css';
import Header from './Components/Header/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Homepage from './Pages/Homepage/Homepage';
import QuizHome from './Pages/Quiz/QuizHome';
import LogoStrip from './Components/LogoStrip/LogoStrip';
import Footer from './Components/Footer/Footer';
import Loading from './Components/Loading/Loading';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Helmet, HelmetProvider } from 'react-helmet-async'
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';



const ImageQuiz = lazy(() => import('./Pages/Quiz/ImageQuiz'));
// const Quiz = lazy(() => import('./Pages/Quiz/Quiz'));
const Contact = lazy(() => import('./Pages/Contact/Contact'));
const Books = lazy(() => import('./Pages/Books/Books'));
const Videos = lazy(() => import("./Pages/Videos/Videos"));
const VideoPlayer = lazy(() => import("./Pages/VideoPlayer/VideoPlayer"));
const Stories = lazy(() => import("./Pages/Stories/Stories"));
const StoriesView = lazy(() => import("./Pages/Stories/StoriesView"));
const ComingSoon = lazy(() => import('./Components/ComingSoon/ComingSoon'));
const Workshop = lazy(() => import('./Pages/Workshop/Workshop'));
const Activities = lazy(() => import('./Pages/Activities/Activities'));
const FillInBlanks = lazy(() => import('./Pages/Activities/FillInBlanks/FillInBlanks'));
const MemoryMatch = lazy(() => import('./Pages/Activities/MemoryMatch/MemoryMatch'));
const PartsOfBody = lazy(() => import('./Pages/Activities/PartsOfBody/PartsOfBody'));
const PunjabiColors = lazy(() => import('./Pages/Activities/PunjabiColors/PunjabiColors'));
// const MatchTheFollowings = lazy(() => import('./Pages/Activities/MatchTheFollowings/MatchTheFollowings'));
const TimeAndDay = lazy(() => import('./Pages/Activities/TimeAndDay/TimeAndDay'));
const ViewStoryPage = lazy(() => import('./Pages/Stories/ViewStoryPage'));
const FruitNVeges = lazy(() => import('./Pages/Activities/FruitsNVeges/FruitNVeges'));
const FamilyMembers = lazy(() => import('./Pages/Activities/FamilyMembers/FamilyMembers'));

function App() {
  return (
    <HelmetProvider>
    <div className="App">
      <BrowserRouter>
        <Header />
        <LogoStrip />
        <DndProvider backend={HTML5Backend}>
          <Routes>
            <Route path='/' element={<Homepage />} />
            <Route path='*' element={<>404 Page not Found</>} />
            <Route path='/quiz' exact element={<QuizHome Helmet={Helmet}/>} />
            <Route path='/workshop' exact element={<Suspense fallback={<Loading />}><Workshop Helmet={Helmet}/></Suspense>} />
            <Route path='/quiz/1' exact element={<Suspense fallback={<Loading />}><ImageQuiz /></Suspense>} />
            <Route path='/quiz/2' exact element={<Suspense fallback={<Loading />}><ComingSoon Helmet={Helmet}/></Suspense>} />
            <Route path='/books' exact element={<Suspense fallback={<Loading />}><Books Helmet={Helmet}/></Suspense>} />
            <Route path='/contactus' exact element={<Suspense fallback={<Loading />}><Contact Helmet={Helmet}/></Suspense>} />
            <Route path='/videos' exact element={<Suspense fallback={<Loading />}><Videos Helmet={Helmet}/></Suspense>} />
            <Route path='/videos/:id' exact element={<Suspense fallback={<Loading />}><VideoPlayer Helmet={Helmet}/></Suspense>} />
            <Route path='/stories' exact element={<Suspense fallback={<Loading />}><Stories Helmet={Helmet}/></Suspense>} />
            <Route path='/stories/:id' exact element={<Suspense fallback={<Loading />}><ViewStoryPage Helmet={Helmet}/></Suspense>} />
            <Route path='/activities' exact element={<Suspense fallback={<Loading />}><Activities Helmet={Helmet}/></Suspense>} />
            <Route path='/activities/fillinblanks' exact element={<Suspense fallback={<Loading />}><FillInBlanks Helmet={Helmet}/></Suspense>} />
            <Route path='/activities/memorymatch' exact element={<Suspense fallback={<Loading />}><MemoryMatch Helmet={Helmet}/></Suspense>} />
            <Route path='/activities/partsofbody' exact element={<Suspense fallback={<Loading />}><PartsOfBody Helmet={Helmet}/></Suspense>} />
            <Route path='/comingsoon' exact element={<Suspense fallback={<Loading />}><ComingSoon Helmet={Helmet}/></Suspense>} />
            <Route path='/activities/punjabicolors' exact element={<Suspense fallback={<Loading />}><PunjabiColors Helmet={Helmet}/></Suspense>} />
            <Route path='/activities/matchgame' exact element={<Suspense fallback={<Loading />}><ComingSoon Helmet={Helmet}/></Suspense>} />
            <Route path='/activities/timeandday' exact element={<Suspense fallback={<Loading />}><TimeAndDay Helmet={Helmet}/></Suspense>} />
            <Route path='/activities/planets' exact element={<Suspense fallback={<Loading />}><StoriesView id="1" Helmet={Helmet}/></Suspense>} />
            <Route path='/activities/wildanimals' exact element={<Suspense fallback={<Loading />}><StoriesView id="2" Helmet={Helmet}/></Suspense>} />
            <Route path='/activities/colors' exact element={<Suspense fallback={<Loading />}><StoriesView id="3" Helmet={Helmet}/></Suspense>} />
            <Route path='/privacypolicy' exact element={<Suspense fallback={<Loading />}><PrivacyPolicy  Helmet={Helmet}/></Suspense>} />
            <Route path='/activities/fruitsandveges' exact element={<Suspense fallback={<Loading />}><FruitNVeges  Helmet={Helmet}/></Suspense>} />
            <Route path='/activities/familymembers' exact element={<Suspense fallback={<Loading />}><FamilyMembers  Helmet={Helmet}/></Suspense>} />
            
          </Routes>
        </DndProvider>
        <Footer />
      </BrowserRouter>
    </div>
    </HelmetProvider>
  );
}

export default App;
