import React from 'react'
import Spinner from 'react-bootstrap/Spinner';

const Loading = () => {

    return (
        <div style={{minHeight : "100vh"}}>
            <Spinner animation="grow" variant='danger' style={{height : "200px", width: "200px"}}/>;
        </div>
    )
    
    
}


export default Loading
