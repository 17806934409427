import React, { Suspense, useState } from 'react'
import Sidebar from '../../Components/Sidebar/Sidebar'
import './Homepage.scss'
import { lazy } from 'react';
import Loading from '../../Components/Loading/Loading';

const AlphabetsLazy = lazy(() => import('../../Components/Alphabets/Alphabets'));

const Homepage = () => {

    const [showLeftSidebar, setShowLeftSidebar] = useState(true)
    const [showLeftSidebar2, setShowLeftSidebar2] = useState(false)
    const [showRightSidebar, setShowRightSidebar] = useState(true)


    const showSidebar = (sidebarInfo) => {
        if (sidebarInfo === "Left Sidebar"){
            setShowLeftSidebar(!showLeftSidebar)
        }if (sidebarInfo === "Right Sidebar"){
            setShowRightSidebar(!showRightSidebar)
        }if (sidebarInfo === "Left Sidebar2"){
            setShowLeftSidebar2(!showLeftSidebar2)
        }
    }


  return (
    <div className='homepage_Container'>
        <section className='leftSidebar_Container'>
            <Sidebar title={"Left Sidebar"} functionClick={showSidebar} showLeftSidebar={showLeftSidebar} />
           
        </section>
        <section className='middle_Container'>
            <Suspense fallback={<Loading/>}>
                <AlphabetsLazy/>
            </Suspense>
        </section>
        {/* <HomeInfo/> */}
        <section className='rightSidebar_Container'>
        <Sidebar title={"Right Sidebar"} functionClick={showSidebar} showRightSidebar={showRightSidebar} />
        </section>
    </div>
  )
}

// const HomeInfo = () => {
//     return (
//         <>
//         HomeInfo
//         </>
//     )
// }

export default Homepage
