import React from 'react'
import './Footer.scss'
import { BiLogoEtsy } from "react-icons/bi";
import { ImYoutube2 } from "react-icons/im";
import { FaInstagram } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='footer'>

      <div className='footer_Middle'>
            <Link to='/privacypolicy' className='footer-privacy-policy'>Punjabi Learning Hub - @Privacy Policy</Link>
      </div>
      <div className='footer_Right'>
          <a href='/' className='footer-right-facebook'><FaInstagram /></a>
          <a href='/' className='footer-right-etsy'><BiLogoEtsy /></a>
          <a href='/' className='footer-right-youtube'><ImYoutube2 /></a>
      </div>
    </div>
  )
}

export default Footer


