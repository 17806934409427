import React, { useEffect } from 'react';
import './PrivacyPolicy.scss';

const PrivacyPolicy = ({Helmet}) => {

    useEffect(()=>{
        window.scroll(0,0)
    },[])

  return (
    <div className="privacy-policy-container">
      <Helmet>
        <title>Privacy Policy | Learn Punjabi</title>
      </Helmet>
      <h1>Privacy Policy</h1>
      <p>Welcome to PunjabiLearningHub , a platform dedicated to teaching beginner-level Punjabi. Your privacy is important to us, and we are committed to protecting the personal information you share with us. This Privacy Policy explains how we collect, use, and protect your information when you visit our website.</p>
      
      <h2>Information We Collect</h2>
      <ul>
        <li><strong>Personal Information:</strong> When you register on our site, subscribe to our newsletter, or fill out a form, you may be asked to enter your name, email address, or other details.</li>
        <li><strong>Usage Data:</strong> We may collect information on how you access and use our website. This data may include your IP address, browser type, the pages you visit, and the time spent on those pages.</li>
      </ul>
      
      <h2>How We Use Your Information</h2>
      <ul>
        <li>To personalize your experience and respond better to your individual needs.</li>
        <li>To improve our website based on the information and feedback we receive from you.</li>
        <li>To administer contests, promotions, surveys, or other site features.</li>
        <li>To send periodic emails regarding your order or other products and services.</li>
      </ul>
      
      <h2>How We Protect Your Information</h2>
      <p>We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. These measures include:</p>
      <ul>
        <li>Secure server hosting</li>
        <li>Encryption of sensitive data</li>
        <li>Regular security audits</li>
      </ul>
      
      <h2>Cookies</h2>
      <p>Our website may use cookies to enhance your experience. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information.</p>
      
      <h2>Third-Party Disclosure</h2>
      <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential.</p>
      
      <h2>Third-Party Links</h2>
      <p>Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</p>
      
      <h2>Your Consent</h2>
      <p>By using our site, you consent to our privacy policy.</p>
      
      <h2>Changes to Our Privacy Policy</h2>
      <p>If we decide to change our privacy policy, we will post those changes on this page. Policy changes will apply only to information collected after the date of the change.</p>
      
      {/* <h2>Contact Us</h2>
      <p>If you have any questions regarding this privacy policy, you may contact us using the information below:</p> */}
      {/* <address>
        [Your Website Name]<br />
        [Your Address]<br />
        [Your Email Address]<br />
        [Your Phone Number]
      </address> */}
    </div>
  );
};

export default PrivacyPolicy;
